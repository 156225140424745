import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "Batch 2005-2008",
      title: "Master of Computer Applications",
      place: "IMR Ghaziabad",
    },
    {
      yearRange: "Batch 2002-2005",
      title: "Bachelor of Science",
      place: "RPPG College Sultanpur",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2017- current",
      title: "IT Consultant",
      place: "Adventus Technocrats Pvt. Ltd.",
    },
    {
      yearRange: "2009 - 2017",
      title: "Software Consultant",
      place: "Axon IT Services",
    },
  ];

  const skills = [
    {
      name: "Tailor-made School/College ERP",
      percent: 100,
    },
    {
      name: "Tailor-made ERP for Hospitals",
      percent: 95,
    },
    {
      name: "Payment Gateway Integration",
      percent: 99,
    },
    {
      name: "Biometric-Machine Integration",
      percent: 90,
    },
    {
      name: "SMS & WhatsApp Integration",
      percent: 95,
    },
    {
      name: "App Design & Development",
      percent: 95,
    },    
    {
      name: "Web Hosting",
      percent: 95,
    },
    {
      name: "Email Hosting",
      percent: 95,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Profile</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Profile
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Resume;
