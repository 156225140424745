import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Tailor-made ERP for Educational Institutions",
      desc: "We provide Tailor-made ERP Solution for educational institutions. eAM is India's most preferred school erp software.",
      icon: "fas fa-laptop",
    },
    {
      name: "Tailor-made ERP for Hospitals",
      desc: "We provide Tailor-made ERP Solution for Hospitals and clinics. eAM can be customized as per individual need.",
      icon: "fas fa-laptop",
    },
    {
      name: "App Design & Development",
      desc: "We've expert team of developers with ample knowledge of their domain. We can design all type of applications as per need of the client.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Payment Gateway Integration",
      desc: "We are expert in the integration of all types of Payment Gateway like PayU, Eazypay, NTT Data (Atom), Razorpay, and Paystack etc.",
      icon: "fas fa-rupee-sign",
    },
    {
      name: "Biometric-machine Integration",
      desc: "We can integrate all types of Biometric-machines. We require SDK only from the manufacturer.",
      icon: "fas fa-fingerprint",
    },
    {
      name: "WhatsApp & SMS Integration",
      desc: "We have integrated WhatsaApp & SMS in our existing applications as well as for the clients.",
      icon: "fab fa-whatsapp-square",
    },
    {
      name: "Web Design",
      desc: "We've dedicated team of web designers for the designing of applications and responsive websites.",
      icon: "fas fa-palette",
    },
    {
      name: "Domain Name Registration",
      desc: "We register all types of Domain name, SSL (Secured Socket Layer) for websites and we provide panel for domain name registration also.",
      icon: "fas fa-globe",
    },
    {
      name: "UI/UX Design",
      desc: "UI/UX is very important part of an application or website. We've creative team of UI/UX Developers.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Web Hosting",
      desc: "We provide Cloud solution, dedicated server, VPS, Shared hosting with all popular providers like CtrlS, AWS, and Microsoft Azure etc.",
      icon: "fas fa-cloud",
    },
    {
      name: "Email Hosting",
      desc: "Corporate Email is essential part of any organization. We provide Google Workspace, Office365, Enterprise, and Business Email.",
      icon: "	fas fa-envelope-square",
    },
    {
      name: "IT Procurement",
      desc: "We've dedicated sales team for IT Procurement of your organization. You've to share the BOQ only.",
      icon: "fas fa-desktop",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
